import industryValueBg1 from "@/assets/images/industryValue/industryValueBg1.png";
import industryValueBg2 from "@/assets/images/industryValue/industryValueBg2.png";
import industryValueBg3 from "@/assets/images/industryValue/industryValueBg3.png";
import industryValueBg4 from "@/assets/images/industryValue/industryValueBg4.png";

// 行业价值
export const IndustryValueList = [
  {
    key: 1,
    title: ["以AIoT技术为基石", "赋能车载新生态"],
    src: industryValueBg1,
  },
  {
    key: 2,
    title: "面向用户",
    content:
      "连接车辆与用户，专注车载智能硬件，打造车内智能空间、高品质车载智能产品，极致用户体验",
    src: industryValueBg2,
  },
  {
    key: 3,
    title: "面向车厂",
    content:
      "提供可以让车辆无限拓展IoT智能硬件的系统解决方案，车厂拥有IoT能力的同时获得持续的后装利润",
    src: industryValueBg3,
  },
  {
    key: 4,
    title: "面向车载硬件厂商",
    content:
      "共享车内入口，打造车载智能硬件开放生态平台，开放车内语控、场景能力，提高车载产品竞争力",
    src: industryValueBg4,
  },
];
