import { useEffect, useState, useCallback } from "react";
import { EnumUserAgent } from "@/config";

function CommonSpotAnimation(props) {
  const [isShow, setShow] = useState(false);

  const getMaxClientWidth = useCallback((ele) => {
    let eleObj = {};
    Array.prototype.slice.call(ele).map((item, index) => {
      eleObj[item.firstChild.clientWidth] = index;
    });

    const objKeys = Object.keys(eleObj);
    let targetObj = {};
    if (objKeys.length) {
      targetObj.maxClientWidth = +[...new Set(objKeys)].sort().reverse()[0];
      const maxIndex = eleObj[targetObj.maxClientWidth];
      targetObj.maxLeft = Array.prototype.slice
        .call(ele)
        [maxIndex].firstChild.getBoundingClientRect().left;
    }
    return targetObj;
  });

  useEffect(() => {
    // 如果是兼容ie
    if (props.userAgent === EnumUserAgent.compatibilityIe) {
      return;
    }

    // 由于没有在tailwindcss中获取到className元素，所以用h2标签来代替
    const eleLists = document.getElementsByTagName("h2");
    setTimeout(() => {
      Array.prototype.slice.call(eleLists)?.map((item, index) => {
        const targetObj = getMaxClientWidth(eleLists);
        const { maxClientWidth, maxLeft } = targetObj;
        let num = maxLeft - maxClientWidth;
        const distance = eleLists[index];
        distance.style.webkitBackgroundClip = "text";

        let timer = null;
        let count = 0;
        timer = setInterval(function () {
          if (count >= 2) {
            clearInterval(timer);
            setShow(true);
          }
          num += props.step ? props.step : 20;
          if (num > maxLeft + maxClientWidth) {
            count++;
            num = maxLeft - maxClientWidth;
          }
          distance.style.backgroundPosition = num + "px 0px";
        }, 50);
      });
    }, 100);
  }, []);

  return (
    <>
      {props.userAgent === EnumUserAgent.compatibilityIe ? (
        <h2
          style={{
            color: `rgba(255, 255, 255,1)`,
          }}
          className="titler-esponsive-font-size-mb2"
        >
          <span style={{ display: "inline-block" }}>{props.children}</span>
        </h2>
      ) : (
        <h2
          style={{
            transition: isShow ? "all ease-in .2s " : null,
            color: `rgba(255, 255, 255, ${isShow ? 1 : 0.5})`,
            backgroundImage:
              "linear-gradient(135deg,rgba(255,255,255,0) 70px,rgba(255,255,255,1) 140px,rgba(255,255,255,0) 210px)",
          }}
          className="titler-esponsive-font-size-mb2"
        >
          <span style={{ display: "inline-block" }}>{props.children}</span>
        </h2>
      )}
    </>
  );
}

export default CommonSpotAnimation;
