import React from "react";
import "animate.css";
import Arrow from "@/assets/images/arrow.png";
import { Link } from "react-router-dom";

const CommonLearnMore = (props) => {
  const { path } = props;
  return (
    <>
      {path.indexOf("http") > -1 ? (
        <a href={`${path}`} target="_blank">
          <div
            className="px-6 py-1 hover:cursor-pointer border rounded-md inline-block common-hover-scale-110"
            style={{ color: "white" }}
          >
            <span style={{ color: "white" }}>
              {props.text ? props.text : "了解更多"}
            </span>
            <img
              src={Arrow}
              alt="向右"
              className="ml-4 inline-block animate-spin-slow"
            />
          </div>
        </a>
      ) : (
        <Link to={`${path}`}>
          <div
            className="px-6 py-1 hover:cursor-pointer border rounded-md inline-block common-hover-scale-110"
            style={{ color: "white" }}
          >
            <span style={{ color: "white" }}>
              {props.text ? props.text : "了解更多"}
            </span>
            <img
              src={Arrow}
              alt="向右"
              className="ml-4 inline-block animate-spin-slow"
            />
          </div>
        </Link>
      )}
    </>
  );
};

export default CommonLearnMore;
