import { useEffect } from "react";

import Footer from "@/compoents/Footer";
import BrandBg3 from "@/assets/images/brandStory/brandBg3.png";
import BrandBg2 from "@/assets/images/brandStory/brandBg2.png";
import BrandBg1 from "@/assets/images/brandStory/brandBg1.png";
import LogoLarge from "@/assets/images/brandStory/logoLarge.png";
import { NavHight } from "@/config";
import CommonAnimation from "@/compoents/CommonAnimation";
import { commonScrollBarToTop, getCompatibilityStyle } from "@/utils/index";

const BrandStory = (props) => {
  // 由于react-router6.2 每次点击第一个路由，滑动页面，再次点击第二个路由，会保存上次的滚动位置，因此当我销毁该组件之前把所有的滚动条都放到最顶部
  useEffect(() => {
    return () => {
      commonScrollBarToTop();
    };
  }, []);

  // 拿兼容ie的样式
  const { white6 } = getCompatibilityStyle(props?.userAgent);

  return (
    <div
      id="brand-wrapper"
      style={{
        paddinhTop: `${NavHight}px`,
        overflowX: "hidden",
        backgroundColor: "black",
      }}
    >
      <div className="relative">
        <img src={BrandBg1} alt={"刺猬物联"} className="w-full" />
        <div
          className="absolute left-0 right-0 top-1/4 text-center w-2/5 m-auto"
          style={{ color: "white" }}
        >
          <CommonAnimation>
            <p className="title-responsive-font-size-mb5">刺猬物联</p>
            <p className="p-content text-left" style={{ color: white6 }}>
              刺猬物联致力于连接车辆、车载硬件与用户、搭建车内智能空间的车载物联方案。
              以AIoT技术为基石，打造与车辆融为一体的后装车载智能硬件；结合车联网大数据，构建车内智能场景
              个性化智能推荐，引领车家互联新潮流，构筑车载AIoT生态圈，引领智慧人·车·生活。
            </p>
          </CommonAnimation>
        </div>
      </div>

      <div className="relative">
        <img src={BrandBg2} alt={"刺猬物联"} className="w-full" />
        <div
          className="absolute left-0  right-0 top-1/4  text-left w-3/5 md:w-2/5 m-auto"
          style={{ color: "white" }}
        >
          <CommonAnimation>
            <img
              src={LogoLarge}
              alt="logo"
              className="w-40 sm:w-52 md:w-60  m-auto mb-8 common-hover-scale-110 text-center"
            />
            <div>
              <p className="p-content-noBottom" style={{ color: white6 }}>
                作为AIoT的先驱者，我们坚持以产品为先，以创意为本，专注打造车载智能硬件产品，呈现完美细节，用科技全方位提升用户驾乘体验。
              </p>
              <p className="p-content-noBottom" style={{ color: white6 }}>
                刺猬物联的logo也不拘泥于传统的圆形或矩形，一根突出的刺正是刺猬精神的符号，既象征着「万物互联
                」的车联生态关系，又传递着「
                不默守陈规、坚持自我、推陈出新」的价值主张。
              </p>
            </div>
          </CommonAnimation>
        </div>
      </div>

      <div className="relative">
        <img src={BrandBg3} alt={"这就是我们"} className="w-full" />
        <div
          style={{ width: "35%", color: "white" }}
          className="absolute  left-20 md:left-28 top-1/2 transform -translate-y-1/2  text-left"
        >
          <CommonAnimation>
            <p className="title-responsive-font-size-mb5">这就是我们</p>
            {[
              "物联网时代与众不同的刺猬。",
              " 不圆滑、不妥协、不将就、不随从，不做夸张的营销，不为市场妥协。",
              "坚持自我，不忘初心，打造让用户眼前一亮的车载智能硬件产品。",
              "呈现完美细节，给用户极致体验。",
            ].map((item, index) => {
              return (
                <p
                  key={index}
                  className="p-content-noBottom"
                  style={{ color: white6 }}
                >
                  {item}
                </p>
              );
            })}
          </CommonAnimation>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default BrandStory;
