import { EnumStyle, EnumUserAgent } from "@/config";

/**
 * 将滚动条放到顶部
 */
const commonScrollBarToTop = () => {
  document?.getElementById(`home-wrapper`)?.scrollIntoView({
    block: "start",
  });
  document?.getElementById(`brand-wrapper`)?.scrollIntoView({
    block: "start",
  });
  document?.getElementById(`industryValue-wrapper`)?.scrollIntoView({
    block: "start",
  });
  document?.getElementById(`developPlatForm-wrapper`)?.scrollIntoView({
    block: "start",
  });
};

const getCompatibilityStyle = (userAgent) => {
  const left = userAgent === EnumUserAgent.compatibilityIe ? "0%" : null;

  const white6 =
    userAgent === EnumUserAgent.compatibilityIe ? EnumStyle.white6 : null;

  const flexSpaceAround =
    userAgent === EnumUserAgent.compatibilityIe
      ? EnumStyle.flexSpaceAround
      : "null";

  const origin =
    userAgent === EnumUserAgent.compatibilityIe ? EnumStyle.origin : null;

  return {
    left,
    white6,
    flexSpaceAround,
    origin,
  };
};

export { commonScrollBarToTop, getCompatibilityStyle };
