import { useState, useCallback, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { throttle } from "throttle-debounce";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

import Footer from "@/compoents/Footer";
import RowLeft from "@/assets/images/home/rowLeft.svg";
import RowLeftActive from "@/assets/images/home/rowLeftActive.svg";
import RowRight from "@/assets/images/home/rowRight.svg";
import RowRightActive from "@/assets/images/home/rowRightActive.svg";
import IndustryBg from "@/assets/images/home/industryBg.png";
import brandBg from "@/assets/images/home/brandBg.png";
import ContactWayB from "@/assets/images/home/contactWayB.png";
import Hedgehog from "@/assets/images/home/hedgehog.png";
import {
  IndustryValueContentList,
  ContentWayList,
  CarouselImgs,
  ensembleLists,
  SmallHeight,
  IntelligentHardwareLists,
} from "./config";
import { commonScrollBarToTop, getCompatibilityStyle } from "@/utils/index";
import CommonLearnMore from "@/compoents/CommonLearnMore";
import { NavHight, EnumUserAgent } from "@/config";
import "./index.css";

const Home = (props) => {
  const [currentImage, setCurrentImg] = useState(1); // 首页轮播图-多张图片时候来表示当前的图片key-向左向右箭头用
  const [key, setKey] = useState(0); // 当前的竖标key
  const keyCurrent = useRef(0); // 当前的竖标key,当缩放页面时候用
  const [levelKey, setLevelKey] = useState(0); // 整体亮点中-当前的图片key
  const carouselImgsLen = CarouselImgs.length;
  const [innerHeight, setInnerHeight] = useState(`${window.innerHeight}px`);
  const [innerWidth, setInnerWidth] = useState(`${window.innerWidth}px`);
  const [innerHeightH, setInnerHeightH] = useState(window.innerHeight);
  const swiperCurent = useRef(null);

  // 由于react-router6.2 每次点击第一个路由，滑动页面，再次点击第二个路由，会保存上次的滚动位置，因此当我销毁该组件之前把所有的滚动条都放到最顶部
  useEffect(() => {
    commonSetHeight();
    return () => {
      commonScrollBarToTop();
    };
  }, []);

  // 当屏幕高度小于430后，首页每一屏的高度将不随页面高度发生变化，固定为430
  const commonSetHeight = useCallback(() => {
    setInnerHeightH(window.innerHeight);
    if (window.innerHeight > SmallHeight) {
      setInnerHeight(`${window.innerHeight}px`);
    } else {
      setInnerHeight(`${SmallHeight}px`);
    }
  });

  //缩放页面
  useEffect(() => {
    const onResize = throttle(50, () => {
      commonSetHeight();
      setInnerWidth(`${window.innerWidth}px`);
      // document.getElementById(`img${keyCurrent.current}`).scrollIntoView({
      //   block: "start",
      // });
    });

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  // 箭头的点击
  const handleClick = useCallback((type) => {
    // clearTimeout(timer);
    // const carouseWrapper = document.getElementById("carouseWrapper");
    // let marginLeft = null;
    // // type 1：向左 2：向右
    // if (type === 1) {
    //   setCurrentImg(currentImage - 1);
    //   // marginLeft = `${
    //   //   +carouseWrapper.style.marginLeft.split("px")[0] + window.innerWidth
    //   // }px`;
    // } else {
    //   setCurrentImg(currentImage + 1);
    //   // marginLeft = `-${currentImage * window.innerWidth}px`;
    // }
    // // carouseWrapper.style.marginLeft = marginLeft;
    // carouseWrapper.style.transition = "all .4s linear";
  });

  // 渲染图片
  const renderImag = (url, height) => {
    return (
      <div
        style={{
          background: `url(${url}) center no-repeat`,
          backgroundSize: "cover",
          height: height ? height : innerHeight,
        }}
      />
    );
  };

  const commonFadeInUp = "wow animate__animated animate__fadeInUp";
  const commonFadeOutUp = "wow animate__animated animate__fadeOutUp";
  const commonFadeInDown = "wow animate__animated animate__fadeInDown";
  const commonFadeOutDown = "wow animate__animated animate__fadeOutDown";

  // 拿兼容ie的样式
  const { white6, origin } = getCompatibilityStyle(props?.userAgent);

  // 兼容SwiperSlide的渲染
  const compatibleSwiperSlideRender = (children, keyType) => {
    return (
      <>
        {props?.userAgent === EnumUserAgent.compatibilityIe ? (
          <>{children}</>
        ) : (
          <SwiperSlide key={keyType}>{children}</SwiperSlide>
        )}
      </>
    );
  };

  // 兼容Swiper的渲染
  const compatibleSwiperRender = (children) => {
    return (
      <>
        {props?.userAgent === EnumUserAgent.compatibilityIe ? (
          <div style={{ backgroundColor: "black" }}>{children}</div>
        ) : (
          <Swiper
            // swiper-no-swiping 禁止手动控制滑动
            direction={"vertical"}
            modules={[Pagination, Mousewheel, Keyboard]}
            className="mySwiper swiper-no-swiping"
            mousewheel={{
              enabled: true, // 可鼠标滚动翻页
              thresholdDelta: 20, //鼠标滚轮滚动距离阈值。当鼠标一次滚动的距离小于该阈值时，swiper 不会切换
              thresholdTime: 200, // 鼠标滚轮滚动时间阈值，当你鼠标滚动太快，两次滚动之间时间小于该阈值时，swiper无法切换
            }}
            keyboard={{
              // 可键盘控制翻页
              enabled: true,
            }}
            stoptouchmove="true"
            speed={500}
            onSlideChange={(swiper) => {
              // slide切换改变
              setKey(swiper?.activeIndex);
            }}
            onSwiper={(swiper) => (swiperCurent.current = swiper)} // 实例化的事件
            style={{ backgroundColor: "black" }}
          >
            {children}
          </Swiper>
        )}
      </>
    );
  };

  // 兼容Nav的渲染
  const compatibleNavRender = (children) => {
    return (
      <>
        {props?.userAgent !== EnumUserAgent.compatibilityIe ? (
          <>{children}</>
        ) : (
          ""
        )}
      </>
    );
  };

  // 兼容动画的渲染
  const compatibleAnimationRender = (animationTypeA, animationTypeB) => {
    let target = null;
    // eslint-disable-next-line no-lone-blocks
    {
      props?.userAgent === EnumUserAgent.compatibilityIe
        ? (target = animationTypeA)
        : (target = animationTypeB);
    }
    return target;
  };

  return (
    <>
      {compatibleNavRender(
        <ul
          style={{
            display: innerHeightH > SmallHeight ? "block" : "none",
            height: "240px",
          }}
          className="fixed left-0 right-0 top-10 bottom-0 m-auto ml-8  z-30 w-1"
        >
          {[0, 1, 2, 3, 4, 5].map((item) => {
            return (
              <li
                className={`w-1 h-9 bg-white mb-1 transform skew-y-35 cursor-pointer`}
                style={{ opacity: key === item ? 1 : 0.3, background: "white" }}
                key={item}
                onClick={() => {
                  swiperCurent.current.slideTo(item);
                  setKey(item);
                  keyCurrent.current = item;
                }}
              ></li>
            );
          })}
        </ul>
      )}

      {compatibleSwiperRender(
        <>
          <>
            {/* 轮播图 */}
            {compatibleSwiperSlideRender(
              <div
                id="img0"
                style={{
                  height: innerHeight,
                }}
              >
                <div className="overflow-hidden w-full relative">
                  {/* 滑动箭头 */}
                  <div
                    // 有图了去掉
                    style={{ opacity: "0" }}
                    className="flex items-center text-white mt-40 absolute top-1/2 left-20 sm:left-28 z-30"
                  >
                    <img
                      src={currentImage === 1 ? RowLeft : RowLeftActive}
                      alt="向左"
                      style={{ padding: "2px", marginTop: "5px" }}
                      className={
                        currentImage === 1
                          ? `home-border border-white border-opacity-60`
                          : `home-border border-homeBorder`
                      }
                      onClick={() =>
                        currentImage === 1 ? null : handleClick(1)
                      }
                    />
                    <img
                      src={
                        currentImage === carouselImgsLen
                          ? RowRight
                          : RowRightActive
                      }
                      alt="向右"
                      style={{ padding: "2px", marginTop: "5px" }}
                      className={
                        currentImage === carouselImgsLen
                          ? `home-border-right  border-white border-opacity-60`
                          : `home-border-right  border-homeBorder`
                      }
                      onClick={() =>
                        currentImage === carouselImgsLen ? null : handleClick(2)
                      }
                    />
                    <div className="text-2xl md:text-3xl">
                      <span>0{currentImage}</span>
                      <span
                        className={`text-${
                          currentImage === carouselImgsLen ? "white" : "gray"
                        }-400`}
                      >
                        /0{carouselImgsLen}
                      </span>
                    </div>
                  </div>

                  {/* 车载智能硬件三张图片 */}
                  <div
                    style={{
                      width: `${innerWidth * carouselImgsLen}px`,
                    }}
                    id="carouseWrapper"
                    className="relative z-20 whitespace-nowrap overflow-x-hidden"
                  >
                    {CarouselImgs.map((item) => {
                      return (
                        <div
                          key={item.key}
                          className={`inline-block w-screen`}
                          style={{
                            height: innerHeight,
                            // display:
                            //   currentImage === item.key ? "inline-block" : "none",
                          }}
                        >
                          <div
                            className="flex flex-col justify-center"
                            style={{
                              height: innerHeight,
                              background: `url(${item.src}) center no-repeat`,
                              backgroundSize: "cover",
                            }}
                          >
                            <div
                              className={compatibleAnimationRender(
                                `home-common ${commonFadeInUp}`,
                                currentImage === item.key && key === 0
                                  ? `home-common ${commonFadeInUp}`
                                  : `home-common ${commonFadeOutUp}`
                              )}
                            >
                              <p
                                className="title-responsive-font-size-mb0"
                                style={{ color: "white" }}
                              >
                                {item.title}
                              </p>
                              <p
                                className="p-content-my4"
                                style={{ color: white6 }}
                              >
                                {item.content}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>,
              1
            )}
          </>

          <>
            {/* 车载智能硬件 */}
            {compatibleSwiperSlideRender(
              <div
                className="flex flex-col justify-center w-full"
                style={{
                  height: innerHeight,
                  backgroundColor: "#0F0F10",
                }}
                id="img1"
              >
                <div className="h-full">
                  {IntelligentHardwareLists.map((item) => {
                    return (
                      <div
                        key={item.title}
                        className="w-1/3 h-full inline-block relative"
                        style={{
                          background: `url(${item.src}) center no-repeat`,
                          backgroundSize: "cover",
                        }}
                      >
                        <div className="w-full absolute left-0 right-0 top-24 m-auto  text-center px-8">
                          <div
                            className={compatibleAnimationRender(
                              `${commonFadeInUp}`,
                              key === 1
                                ? `${commonFadeInUp}`
                                : `${commonFadeOutUp}`
                            )}
                          >
                            <p
                              className="titler-esponsive-font-size-mb2"
                              style={{ color: "white" }}
                            >
                              {item.title}
                            </p>
                            <p
                              className="p-content space-nowrap"
                              style={{ color: white6 }}
                            >
                              {item.content}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>,
              2
            )}
          </>

          <>
            {/* 整体亮点 */}
            {compatibleSwiperSlideRender(
              <div
                className="flex flex-col justify-center relative overflow-hidden"
                style={{
                  height: innerHeight,
                }}
                id="img2"
              >
                {/* 图片列 */}
                <div
                  style={{
                    width: `${window.innerWidth * ensembleLists.length}px`,
                    overflow: "hidden",
                  }}
                  id="ensembleWrapper"
                >
                  {ensembleLists.map((item) => {
                    return (
                      <div
                        key={item.key}
                        className={`inline-block w-screen relative`}
                        style={{
                          display: levelKey === item.key ? "block" : "none",
                        }}
                      >
                        <div
                          className="flex flex-col justify-center"
                          style={{
                            // backgroundColor: "rgb(17,17,17)",
                            backgroundColor: "black",
                            height: innerHeight,
                            position: "relative",
                          }}
                        >
                          <div
                            data-wow-duration="3s"
                            className="absolute z-10 left-0 right-0 m-auto text-center"
                            style={{
                              top: "13%",
                            }}
                          >
                            <div
                              className={compatibleAnimationRender(
                                `${commonFadeInUp}`,
                                item.key === levelKey && key === 2
                                  ? `${commonFadeInUp}`
                                  : `${commonFadeOutUp}`
                              )}
                            >
                              <p
                                className="title-responsive-font-size"
                                style={{ color: "white" }}
                              >
                                {item.title}
                              </p>
                            </div>
                          </div>
                          <div
                            style={{
                              background: `url(${item.src}) center no-repeat`,
                              backgroundSize: "cover",
                              height: innerHeight,
                            }}
                          ></div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* 图片切换 */}
                <ul
                  className="absolute flex  transform left-0 right-0 m-auto bottom-0"
                  style={{ width: "60%", color: "white", height: "35px" }}
                >
                  {ensembleLists.map((item) => {
                    return (
                      <li className="w-1/3 text-center" key={item.key}>
                        <span
                          className="border-b-2 border-solid p-2 cursor-pointer"
                          onClick={() => setLevelKey(item.key)}
                          style={{
                            borderBottomColor:
                              item.key === levelKey ? "#EA644F" : "#fff",
                            opacity: item.key === levelKey ? 1 : 0.6,
                          }}
                        >
                          {item.title}
                        </span>
                      </li>
                    );
                  })}
                </ul>
                {/* 底部线模拟border */}
                <div
                  className="absolute w-full bottom-1 "
                  style={{
                    height: "1px",
                    backgroundColor: "#fff",
                    opacity: 0.38,
                  }}
                ></div>
              </div>,
              3
            )}
          </>

          <>
            {/* 品牌特色 */}
            {compatibleSwiperSlideRender(
              <div
                className="flex flex-col justify-center relative overflow-y-hidden w-full"
                style={{
                  height: innerHeight,
                }}
                id="img3"
              >
                {renderImag(brandBg, innerHeight)}
                <div
                  className={compatibleAnimationRender(
                    `home-common ${commonFadeInUp}`,
                    key === 3
                      ? `home-common ${commonFadeInUp}`
                      : `home-common ${commonFadeOutUp}`
                  )}
                  style={{
                    height: "140px",
                    top: 0,
                    bottom: 0,
                    margin: "auto",
                  }}
                >
                  <p
                    className="title-responsive-font-size-mb0"
                    style={{ color: "white" }}
                  >
                    品牌特色
                  </p>
                  <p className="p-content mt-4 mb-0" style={{ color: white6 }}>
                    我是一只刺猬，很酷的那种，不圆滑、不妥协、不将就、不随从
                  </p>
                  <p
                    className="p-content mb-4 text-left"
                    style={{ color: white6 }}
                  >
                    坚持自我，呈现细节完美的产品，给用户极致体验
                  </p>
                  <CommonLearnMore path="/brandStory" />
                </div>
                <img
                  data-wow-delay=".2s"
                  src={Hedgehog}
                  alt="刺猬"
                  className={
                    key === 3
                      ? `z-10 absolute right-20 -bottom-32 w-1/3  wow animate__animated animate__pulse`
                      : `z-10 absolute right-20 -bottom-32 w-1/3`
                  }
                />
              </div>,
              4
            )}
          </>

          <>
            {/* 行业价值 */}
            {compatibleSwiperSlideRender(
              <div
                className="flex flex-col justify-center relative w-full"
                style={{
                  height: innerHeight,
                }}
                id="img4"
              >
                {renderImag(IndustryBg, innerHeight)}
                <div
                  className={compatibleAnimationRender(
                    `home-common ${commonFadeInUp}`,
                    key === 4
                      ? `home-common ${commonFadeInUp}`
                      : `home-common ${commonFadeOutUp}`
                  )}
                  style={{
                    height: "120px",
                    top: 0,
                    bottom: 0,
                    margin: "auto",
                  }}
                >
                  <p
                    className="title-responsive-font-size-mb0"
                    style={{ color: "white" }}
                  >
                    行业价值
                  </p>
                  <div className="p-content-my4">
                    {IndustryValueContentList.map((item) => {
                      return (
                        <span
                          key={item}
                          className="p-content px-3 border-r border-solid last:border-none first:pl-0"
                          style={{ color: white6 }}
                        >
                          {item}
                        </span>
                      );
                    })}
                  </div>
                  <CommonLearnMore path="/industryValue" />
                </div>
              </div>,
              5
            )}
          </>

          <>
            {/* 了解更多 */}
            {compatibleSwiperSlideRender(
              <div
                className="text-center flex flex-col justify-between w-full"
                style={{
                  background: `url(${ContactWayB}) center no-repeat`,
                  backgroundSize: "cover",
                  height: innerHeightH < 568 ? "568px" : innerHeight,
                }}
                id="img5"
              >
                <div className=" z-10  w-full h-full flex flex-col justify-center">
                  <div
                    className={compatibleAnimationRender(
                      "",
                      key === 5 ? `${commonFadeInDown}` : `${commonFadeOutDown}`
                    )}
                  >
                    <p
                      className="title-responsive-font-size"
                      style={{ color: "white", marginTop: `${NavHight}px` }}
                    >
                      了解更多
                    </p>
                  </div>
                  <div className="flex flex-col md:flex-row items-center justify-center mt-8 lg:mt-20">
                    {/* <div className="flex flex-col md:flex-row items-center justify-center md:px-52 lg:px-60 mt-8 lg:mt-20"> */}
                    {ContentWayList.content.map((item) => {
                      return (
                        <div
                          key={item.key}
                          className={`${
                            item.key === 1
                              ? "flex items-center md:block md:mr-28 lg:mr-50"
                              : "flex items-center md:block"
                          }`}
                        >
                          <img
                            src={item.src}
                            alt={item.title}
                            className={
                              item.isEqual
                                ? "w-20 mr-4 md:m-auto  cursor-pointer  common-hover-scale-110"
                                : "w-20 md:w-24  mr-4 md:mr-0 cursor-pointer  common-hover-scale-110"
                            }
                            style={{
                              marginBottom: item.isEqual ? "0px" : "1rem",
                            }}
                          />
                          <div
                            className={compatibleAnimationRender(
                              `w-64 md:w-auto my-8`,
                              key === 5
                                ? `w-64 md:w-auto my-8 ${commonFadeInUp}`
                                : `w-64 md:w-auto my-8 ${commonFadeOutUp}`
                            )}
                          >
                            {item.isEqual ? (
                              <Link
                                to={`/developerPlatform`}
                                className="text-white"
                              >
                                <p
                                  className="bg-developC px-6 py-1 rounded inline-block mt-2 md:mt-0  cursor-pointer common-hover-scale-110"
                                  style={{
                                    color: "white",
                                    backgroundColor: origin,
                                  }}
                                >
                                  {item.title}
                                </p>
                              </Link>
                            ) : (
                              <p className="mb-0" style={{ color: "white" }}>
                                {item.title}
                              </p>
                            )}
                            <p
                              className="p-content-noBottom"
                              style={{ color: white6 }}
                            >
                              {item.desc}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <Footer />
              </div>,
              6
            )}
          </>
        </>
      )}
    </>
  );
};

export default Home;
