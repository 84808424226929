import React, { useEffect } from "react";

import { WOW } from "wowjs";
import "animate.css";

const CommonAnimation = ({ children }) => {
  // 元素进入到可视区加载当前元素的animation动画
  useEffect(() => {
    new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: false,
    }).init();
  }, []);

  const slideUpAnimation = "wow animate__animated animate__fadeInUp";

  return <div className={`${slideUpAnimation}`}>{children}</div>;
};

export default CommonAnimation;
