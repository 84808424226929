import Icon1 from "@/assets/images/developPlatform/icon1.png";
import Icon2 from "@/assets/images/developPlatform/icon2.png";
import Icon3 from "@/assets/images/developPlatform/icon3.png";
import Icon4 from "@/assets/images/developPlatform/icon4.png";
import Process1 from "@/assets/images/developPlatform/process1.png";
import Process2 from "@/assets/images/developPlatform/process2.png";
import Process3 from "@/assets/images/developPlatform/process3.png";
import Process4 from "@/assets/images/developPlatform/process4.png";
import Process5 from "@/assets/images/developPlatform/process5.png";

// 刺猬物联
export const DeveloperPlatformList = [
  {
    key: 1,
    title: "语音控制",
    content: "可享车内语音直控",
    src: Icon1,
  },
  {
    key: 2,
    title: "智能推荐",
    content: "智能推荐生态产品",
    src: Icon2,
  },
  {
    key: 3,
    title: "互动联动",
    content: "场景化多产品联动",
    src: Icon3,
  },
  {
    key: 4,
    title: "车场合作",
    content: "车场精品供应机会",
    src: Icon4,
  },
];

// 接入流程
export const ProcssList = [
  {
    key: 1,
    title: "申请成为开发者",
    content: ["在平台注册开发者资质", " 获取平台开发者权限"],
    src: Process1,
  },
  {
    key: 2,
    title: "定义产品",
    content: ["在平台创建产品", "并定义产品功能", "确认接入方案，产品即可立项"],
    src: Process2,
  },
  {
    key: 3,
    title: "研发产品",
    content: ["进行产品研发", "软硬件功能开发及测试"],
    src: Process3,
  },
  {
    key: 4,
    title: "审核产品",
    content: ["产品实体提测", "由平台审核确认"],
    src: Process4,
  },
  {
    key: 5,
    title: "上线发布",
    content: ["审核通过产品上线发布", "进入刺猬生态渠道"],
    src: Process5,
  },
];
