import { useEffect } from "react";

import Footer from "@/compoents/Footer";
import { NavHight } from "@/config";
import { IndustryValueList } from "./config";
import CommonAnimation from "@/compoents/CommonAnimation";
import { commonScrollBarToTop, getCompatibilityStyle } from "@/utils/index";
import CommonSpotAnimation from "@/compoents/commonSpotAnimation";

const IndustryValue = (props) => {
  // 由于react-router6.2 每次点击第一个路由，滑动页面，再次点击第二个路由，会保存上次的滚动位置，因此当我销毁该组件之前把所有的滚动条都放到最顶部
  useEffect(() => {
    return () => {
      commonScrollBarToTop();
    };
  }, []);

  // 拿兼容ie的样式
  const { left, white6 } = getCompatibilityStyle(props?.userAgent);

  return (
    <div
      id="industryValue-wrapper"
      style={{ paddingTop: `${NavHight}px`, backgroundColor: "black" }}
    >
      <div className="relative">
        <img
          src={IndustryValueList[0].src}
          alt={IndustryValueList[0].title}
          style={{ width: "100%" }}
        />
        <div className="common-center" style={{ top: "32%", left: left }}>
          {IndustryValueList[0].title.map((item) => {
            return (
              <CommonAnimation key={item}>
                <CommonSpotAnimation userAgent={props?.userAgent}>
                  {item}
                </CommonSpotAnimation>
              </CommonAnimation>
            );
          })}
        </div>
      </div>

      {IndustryValueList.filter((item2) => item2.key !== 1).map((item) => {
        return (
          <div className="relative" key={item.key}>
            <img src={item.src} alt={item.title} style={{ width: "100%" }} />
            <div
              style={{ width: "33%", color: "white" }}
              className="absolute  left-20 md:left-28 top-1/2 transform -translate-y-1/2  text-left"
            >
              <CommonAnimation>
                <p className="title-responsive-font-size-mb5">{item.title}</p>
                <p className="p-content-noBottom" style={{ color: white6 }}>
                  {item.content}
                </p>
              </CommonAnimation>
            </div>
          </div>
        );
      })}
      <Footer />
    </div>
  );
};

export default IndustryValue;
