import { useEffect, useState } from "react";

import { throttle } from "throttle-debounce";

import { NavHight, EnumStyle } from "@/config";
import { commonScrollBarToTop, getCompatibilityStyle } from "@/utils/index";
import CommonAnimation from "@/compoents/CommonAnimation";
import CommonLearnMore from "@/compoents/CommonLearnMore";
import CommonSpotAnimation from "@/compoents/commonSpotAnimation";
import Footer from "@/compoents/Footer";
import developPlatformBg1 from "@/assets/images/developPlatform/developPlatformBg1.png";
import developPlatformBg2 from "@/assets/images/developPlatform/developPlatformBg2.png";
import developPlatformBg4 from "@/assets/images/developPlatform/developPlatformBg4.png";
import developPlatformBg3 from "@/assets/images/developPlatform/developPlatformBg3.png";
import { DeveloperPlatformList, ProcssList } from "./config";

const DeveloperPlatform = (props) => {
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  // 由于react-router6.2 每次点击第一个路由，滑动页面，再次点击第二个路由，会保存上次的滚动位置，因此当我销毁该组件之前把所有的滚动条都放到最顶部
  useEffect(() => {
    return () => {
      commonScrollBarToTop();
    };
  }, []);

  //缩放页面
  useEffect(() => {
    const onResize = throttle(50, () => {
      setInnerHeight(window.innerHeight);
      setInnerWidth(window.innerWidth);
    });

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  // 拿兼容ie的样式
  const { left, white6, flexSpaceAround } = getCompatibilityStyle(
    props?.userAgent
  );

  return (
    <div
      id="developPlatForm-wrapper"
      style={{ paddingTop: `${NavHight}px`, backgroundColor: "black" }}
    >
      <div className="relative">
        <img src={developPlatformBg1} alt={"开发者平台"} className="w-full" />
        <div className="common-center" style={{ top: "30%", left: left }}>
          <CommonAnimation>
            {["刺猬物联车载IoT生态", "为后装硬件打通上车通道"].map((item) => {
              return (
                <CommonSpotAnimation
                  key={item}
                  step={25}
                  userAgent={props?.userAgent}
                >
                  {item}
                </CommonSpotAnimation>
              );
            })}
          </CommonAnimation>
        </div>
      </div>

      <div
        style={{
          background: `url(${developPlatformBg2}) center no-repeat`,
          backgroundSize: "cover",
          height: `${innerHeight}px`,
          color: "#fff",
        }}
        className="flex flex-col items-center justify-center text-center px-16 sm:px-28 md:px-32 "
      >
        <div>
          <CommonAnimation>
            <p className="title-responsive-font-size-mb5">刺猬物联开发者平台</p>
            <p
              className="p-content-noBottom"
              style={{ color: white6, width: "78vw" }}
            >
              刺猬物联平台面向车载智能硬件厂商开放车内入口，赋能后装车载产品，共享车内语音控制、智能化场景能力发现更多使用可能，与合作商共同搭建车联网时代车载生态。
            </p>
          </CommonAnimation>
        </div>
        <ul
          className="flex mt-20 mb-0 w-full  justify-evenly flex-wrap"
          style={{ justifyContent: flexSpaceAround }}
        >
          {DeveloperPlatformList.map((item) => {
            return (
              <li key={item.key} className="w-1/2 mb-12 sm:w-auto sm:mb-0">
                <CommonAnimation>
                  <img
                    src={item.src}
                    alt={item.title}
                    className="w-10 m-auto common-hover-scale-125"
                  />
                  <div className="text-white">{item.title}</div>
                  <div style={{ color: EnumStyle.gray4 }}>{item.content}</div>
                </CommonAnimation>
              </li>
            );
          })}
        </ul>
      </div>

      <div
        style={{
          background: `url(${developPlatformBg3}) center no-repeat`,
          backgroundSize: "cover",
          height: `${
            innerWidth <= 1167 || innerHeight < 500
              ? "auto"
              : `${innerHeight}px`
          } `,
        }}
        className="flex flex-col items-center  justify-center text-center pl-14 pr-6 md:pl-28 md:pr-20 lg:pl-40 lg:pr-32 py-16 pt-20"
      >
        <CommonAnimation>
          <p
            className="title-responsive-font-size-mb5"
            style={{ color: "white" }}
          >
            接入流程
          </p>
        </CommonAnimation>
        <ul
          className="flex mt-16 w-full justify-center flex-wrap"
          style={{ flexGlow: "wrap" }}
        >
          {ProcssList.map((item) => {
            return (
              <li
                key={item.key}
                className="w-64 bg-processBg p-5  pt-12 relative mb-10 sm:mr-8 common-hover-scale-110"
                style={{ background: "rgb(42,42,45)" }}
              >
                <img
                  src={item.src}
                  alt={item.title}
                  style={{ width: item.key === 1 ? "4.8rem" : "6rem" }}
                  className="m-auto absolute -top-4 left-1/2 transform -translate-x-1/2 "
                />
                <div
                  data-wow-duration="3s"
                  className="wow animate__animated  animate__fadeIn"
                  style={{ color: "white" }}
                >
                  {item.title}
                </div>
                {item.content.map((item2) => {
                  return (
                    <div
                      data-wow-duration="3s"
                      className="p-content wow animate__animated  animate__fadeIn"
                      key={item2}
                      style={{ color: white6 }}
                    >
                      {item2}
                    </div>
                  );
                })}
              </li>
            );
          })}
        </ul>
      </div>

      <div
        className="relative flex justify-center"
        style={{
          background: `url(${developPlatformBg4}) center no-repeat`,
          backgroundSize: "cover",
          height: `${window.innerHeight}px`,
        }}
      >
        <div className="flex justify-center items-center text-center">
          <CommonAnimation>
            <p
              className="title-responsive-font-size-mb5"
              style={{ color: "white" }}
            >
              接入生态方式
            </p>
            <p className="p-content-noBottom" style={{ color: white6 }}>
              车载硬件产商可通过嵌入刺猬模组或集成SDK的方式使产品接入刺猬物联生态
            </p>
            <p className="p-content" style={{ color: white6 }}>
              更多详细内容参见开发者文档
            </p>
            <CommonLearnMore
              text="进入开发者平台"
              path="http://developer.ciweiaiot.com/"
            />
          </CommonAnimation>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DeveloperPlatform;
