import React from "react";

import NotFound from "./compoents/NotFound";
import Home from "./pages/Home";
import BrandStory from "./pages/BrandStory";
import IndustryValue from "./pages/IndustryValue";
import DeveloperPlatform from "./pages/DeveloperPlatform";
import { Navigate } from "react-router-dom";

let userAgent = 0;
const data = navigator.userAgent.toLowerCase();

// 表示是ie的兼容模式
if (data.indexOf("trident") > -1) {
  userAgent = 1;
} else {
  // 其他
  userAgent = 0;
}

export const RoutesList = [
  {
    name: "首页",
    path: "/home",
    element: <Home userAgent={userAgent} />,
    key: 0,
  },
  {
    name: "品牌故事",
    path: "/brandStory",
    element: <BrandStory userAgent={userAgent} />,
    key: 1,
  },
  {
    name: "行业价值",
    path: "/industryValue",
    element: <IndustryValue userAgent={userAgent} />,
    key: 2,
  },
  {
    name: "开发者平台",
    path: "/developerPlatform",
    element: <DeveloperPlatform userAgent={userAgent} />,
    key: 3,
  },
  {
    path: "*",
    element: <NotFound />,
    key: 4,
  },
  {
    path: "/",
    element: <Navigate to="/home" />,
    key: 5,
  },
];
