import React, { useEffect, useState } from "react";
import { Routes, BrowserRouter, Route, NavLink, Link } from "react-router-dom";

import { throttle } from "throttle-debounce";

import Logo from "../assets/images/logo.png";
import { RoutesList } from "../routes";
import { NavHight, SmallScreenW, EnumStyle } from "../config";
import "../index.css";

function BasicLayout() {
  const [isClose, setClose] = useState(false);
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  //缩放页面
  useEffect(() => {
    const onResize = throttle(50, (e) => {
      setInnerHeight(window.innerHeight);

      if (e.target.innerWidth > SmallScreenW) setClose(false);
    });
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  // 当页面缩小，菜单需要下拉来展示的时候，禁止滚动条滚动
  useEffect(() => {
    // document.body.style.overflow = isClose ? "hidden" : "auto";
  }, [isClose]);

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <BrowserRouter>
        {/* 大屏下的nav */}
        <div
          className="text-gray-400 top-0 overflow-hidden fixed w-full z-50"
          style={{
            background: "linear-gradient(180deg, #000 0%, #000 100%)",
            height: `${NavHight}px`,
            color: EnumStyle.gray4,
          }}
        >
          <div
            className="text-4xl align-middle cursor-pointer hover:text-white inline-block pl-6 md:hidden"
            onClick={() => {
              setClose(!isClose);
            }}
            style={{ lineHeight: !isClose ? "2.25rem" : "2rem" }}
          >
            {!isClose ? "=" : "x"}
          </div>
          <Link to="/home">
            <img
              src={Logo}
              alt="logo"
              className="float-left absolute left-1/2 transform -translate-x-1/2 top-0 md:left-6  sm:transform-none w-ful h-full"
            />
          </Link>
          <ul
            style={{
              lineHeight: `${NavHight - 3}px`,
              height: isClose ? "100%" : "auto",
            }}
            className="w-3/4 lg:w-1/2  fixed top-0 right-0  hidden md:block"
          >
            {RoutesList.filter((item) => item.name).map((item) => {
              return (
                <li
                  key={item.key}
                  className={`h-full w-1/4 inline-block text-right cursor-point`}
                >
                  <div>
                    <NavLink
                      to={item.path}
                      style={(props) => {
                        return props.isActive
                          ? {
                              color: "#EA644F",
                              display: "inline-block",
                              height: "100%",
                              padding: "0 1.5rem",
                              borderBottom: "2px solid #EA644F",
                            }
                          : {
                              color: "white",
                              display: "inline-block",
                              padding: "0 1.5rem",
                              height: "100%",
                            };
                      }}
                    >
                      <span className="hover:text-activeNav">{item.name}</span>
                    </NavLink>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

        {/* 小屏下的nav */}
        <ul
          className="w-full  fixed left-0 pl-14 text-gray-400 z-40  overflow-y-auto  cursor-pointer"
          style={{
            top: isClose ? `${NavHight}px` : "-100%",
            transition: "top .3s linear",
            height: `${innerHeight - NavHight}px`,
            background: "black",
          }}
        >
          {RoutesList.filter((item) => item.name).map((item) => {
            return (
              <li
                key={item.key}
                className="border-b border-solid border-normalGray py-4 cursor-pointer hover:text-white"
                onClick={() => setClose(!isClose)}
                style={{ borderColor: "gray" }}
              >
                <NavLink
                  to={item.path}
                  style={(props) => {
                    return props.isActive
                      ? {
                          color: "#EA644F",
                        }
                      : {
                          color: "white",
                        };
                  }}
                >
                  <span className="hover:text-activeNav">{item.name}</span>
                </NavLink>
              </li>
            );
          })}
        </ul>
        {/* </div> */}

        {/* nav下的页面渲染 */}
        <Routes>
          {RoutesList.map((item) => {
            return (
              <Route
                key={item.key}
                path={item.path}
                element={item.element}
              ></Route>
            );
          })}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default BasicLayout;
