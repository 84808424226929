/*
 * @Author: 杨洁 jieyang@szlanyou.com
 * @Date: 2023-12-25 16:48:58
 * @LastEditors: 杨洁 jieyang@szlanyou.com
 * @LastEditTime: 2023-12-29 11:42:54
 * @FilePath: \ciweihome\src\config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// nav的高度
export const NavHight = 40;

//小屏的宽度
export const SmallScreenW = 768;

// footer
export const footerList = [
  {
    key: 1,
    title: "主机厂合作",
    value: "service@szlanyou.com",
  },
  {
    key: 2,
    title: "车载硬件合作",
    value: "service@szlanyou.com",
  },
  // {
  //   key: 3,
  //   title: "售前咨询",
  //   value: "xxxxxx@ciweiaiot.com",
  // },
  // {
  //   key: 4,
  //   title: "公司地址",
  //   value: "广州市花都区xxxx",
  // },
];

// 浏览器模式
export const EnumUserAgent = {
  compatibilityIe: 1, //ie兼容模式
  firefox: 2, //火狐
  chrome: 3, // chrome
};

export const EnumStyle = {
  white6: "rgba(255, 255, 255, 0.6)",
  flexSpaceAround: "space-around",
  gray4: "#9ca3af",
  origin: "#E84F3B",
  navLinkColor: "#EA644F",
};
