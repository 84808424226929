import DevelopImg from "@/assets/images/home/developImg.png";
import AppImg from "@/assets/images/home/appImg.png";
import Carousel1 from "@/assets/images/home/carousel1.png";
import IntelligentHardwareImg1 from "@/assets/images/home/intelligentHardwareImg1.png";
import IntelligentHardwareImg2 from "@/assets/images/home/intelligentHardwareImg2.png";
import IntelligentHardwareImg3 from "@/assets/images/home/intelligentHardwareImg3.png";
import SpeechControl from "@/assets/images/home/speechControl.png";
import Carmachine from "@/assets/images/home/carmachine.png";
import IntelligentLinkage from "@/assets/images/home/intelligentLinkage.png";

//行业价值轮播图
export const IndustryValueContentList = [
  "车载智能硬件潮牌",
  "车载IoT生态平台",
  "车载IoT解决方案供应商",
];

//了解更多轮播图
export const ContentWayList = {
  content: [
    {
      src: AppImg,
      title: "关注公众号",
      desc: "获取最新动态",
      isEqual: false,
      key: 1,
    },
    {
      src: DevelopImg,
      title: "进入开发者平台",
      desc: "加入刺猬物联生态，开启车载IoT新时代",
      isEqual: true,
      key: 2,
    },
    // {
    //   src: AppImg,
    //   title: "下载车载app",
    //   desc: "购买更多车载产品",
    //   isEqual: false,
    // },
  ],
};

// 轮播图
export const CarouselImgs = [
  {
    key: 1,
    title: "车载智能硬件",
    content: "让车载智能硬件与车辆融为一体，用科技提升驾乘体验",
    src: Carousel1,
  },
  {
    key: 2,
    title: "车载智能硬件2",
    content: "让车载智能硬件与车辆融为一体，用科技提升驾乘体验2",
    src: SpeechControl,
  },
  {
    key: 3,
    title: "车载智能硬件3",
    content: "让车载智能硬件与车辆融为一体，用科技提升驾乘体验2",
    src: Carmachine,
  },
  // {
  //   key: 4,
  //   title: "车载智能硬件4",
  //   content: "让车载智能硬件与车辆融为一体，用科技提升驾乘体验2",
  //   src: Carousel1,
  // },
];

//车载智能硬件
export const IntelligentHardwareLists = [
  {
    title: "车载空气净化器",
    content: "车内实时净化，一路安全清新",
    src: IntelligentHardwareImg1,
  },
  {
    title: "智能语音助手",
    content: "回应你的每次呼唤，做你路上的得力助手",
    src: IntelligentHardwareImg2,
  },
  {
    title: "冷热杯",
    content: "冷热随意调，恒温更贴心",
    src: IntelligentHardwareImg3,
  },
];

//整体亮点
export const ensembleLists = [
  {
    key: 0,
    title: "语音控制",
    src: SpeechControl,
  },
  {
    key: 1,
    title: "车机一体化",
    src: Carmachine,
  },
  {
    key: 2,
    title: "智能联动",
    src: IntelligentLinkage,
  },
];

//设置的当高度小于多少后，首页每一屏的高度将不随页面高度发生变化
export const SmallHeight = 430;
