import React from "react";
import { footerList } from "@/config";

function Footer() {
  const commonFadeIn = "wow animate__animated  animate__fadeIn";
  return (
    <div
      className="text-center py-6 px-16"
      style={{ background: "#1B1B1B", color: "#9ca3af" }}
    >
      {footerList.map((item) => {
        return (
          <div
            data-wow-duration="2.5s"
            className={`w-full sm:w-1/2 lg:w-1/4  inline-block ${commonFadeIn}`}
            key={item.key}
          >
            <p
              className={
                item.key === footerList.length
                  ? "mb-0 footer-common"
                  : "mb-4 sm:mb-0 footer-common"
              }
            >
              {item.title}：{item.value}
            </p>
          </div>
        );
      })}

      <div data-wow-duration="2.5s" className={`mt-4 ${commonFadeIn}`}>
        Copyright © 2021 深圳联友科技有限公司 版权所有{" "}
        <a
          href="https://beian.miit.gov.cn/#/Integrated/index"
          target="_blank"
          style={{ color: "#9ca3af", cursor: 'pointer' }}
        >
          粤ICP备11069161号
        </a>
      </div>
    </div>
  );
}

export default Footer;
